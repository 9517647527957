import {
	Button,
	Card,
	Checkbox,
	Modal,
	Select,
	Spin,
	message,
	notification,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {
	addCategory,
	addSchema,
	addTag,
	createPost,
	deleteSchema,
	editPost,
	getAllCate,
	getAllSchemas,
	getAllTag,
	getAllUsers,
	getPostById,
	getPostBySlug,
	getpagingDomains,
} from '../../helpers/helper'

import { Editor } from '@tinymce/tinymce-react'
import { MdOutlineEdit } from 'react-icons/md'
import { error, success } from '../../Components/Common/message'
import toSlug from '../../common/function'
import {
	deleteImageBunny,
	getListImageBunny,
	uploadFileToBunny,
} from '../../helpers/api_bunny'
import useDebounce from '../../helpers/useDebounce'
import TagComp from './tag'
import { PictureOutlined } from '@ant-design/icons'
import './style.scss'
const { Meta } = Card

const { Option } = Select
const authUser = localStorage.getItem('authUser')
	? JSON.parse(localStorage.getItem('authUser'))
	: null

const initialData = {
	_id: '',
	title: '',
	domains: [],
	categories: [],
	tags: [],
	views: 0,
	likes: 0,
	status: 0,
	userid: authUser?.id,
	thumb: '',
	thumbAlt: '',
	content: '',
	keyword: [],
	slug: '',
	description: '',
	post_schemaid: [],
	isNoIndex: false,
	isNoFollow: false,
	isTOC: false,
	questions: [],
}

function CreateEditPost() {
	const [isLoading, setIsLoading] = useState(false)
	const [schemaList, setSchemaList] = useState([])
	const [schemas, setSchemas] = useState([])
	const [posts, setPosts] = useState([])
	const [domain, setDomain] = useState([])
	const [user, setUser] = useState([])
	const [cateList, setCateList] = useState([])
	const [tagList, setTagList] = useState([])
	const [keyword, setFocusKeyword] = useState([])
	const [formTax, setFormTax] = useState([])
	const [mediaList, setMediaList] = useState([])
	const [formAdd, setFormAdd] = useState(initialData)
	const [isNoIndex, setIsNoIndex] = useState(false)
	const [isNoFollow, setIsNoFollow] = useState(false)
	const [isTOC, setIsTOC] = useState(false)
	const editorDescriptionRef = useRef(null)
	const editorContentRef = useRef(null)
	// const [slugValue, setSlugVlue] = useState();
	const [isModalAddSchemaVisible, setIsModalVisible] = useState(false)
	const [isModalEditImage, setIsModalEditImage] = useState(false)
	const [isModalChooseImage, setIsModalChooseImage] = useState(false)
	const [isConfirmModalVisible, setConfirmModalVisible] =
		useState(false)
	const [imageChoose, setImageChoose] = useState()

	const [questions, setQuestions] = useState([
		{ question: '', answer: '' },
	])

	const location = useLocation()
	const id = location?.state?.id || ''

	const { slug } = useParams()
	const history = useHistory()
	const slugDebounce = useDebounce(formAdd.slug, 300)

	const [listImage, setListImage] = useState([])

	useEffect(() => {
		if (!slug && slugDebounce) {
			if (slugDebounce) {
				getPostBySlug(slugDebounce)
					.then((res) => {
						if (Object.keys(res).length > 0) {
							setFormAdd({
								...res,
								id: res?._id,
								thumb: res.thumb,
								thumbAlt: res.thumbAlt || '',
								status: res.status,
								categories: res.categories?.map((i) => i._id),
								tags: res.tags?.map((i) => i._id),
								keyword: res.keyword,
								isNoFollow: res?.isNoFollow,
								isNoIndex: res?.isNoIndex,
								isTOC: res?.isTOC,
								post_schemaid: res.post_schemaid?.map((i) => i._id),
								userid: res.userid?.id,
								faqPage: res?.faqPage || {},
								questions: res?.questions,
							})
							setIsNoIndex(res?.isNoIndex)
							setIsNoFollow(res?.isNoFollow)
							setIsTOC(res?.isTOC)
							const KeyFocus = res?.keyword?.map((item, i) => ({
								id: new Date().getTime() + i,
								text: item,
							}))
							setFocusKeyword(KeyFocus)
						} else {
							setFormAdd({
								...formAdd,
								_id: '',
							})
						}
						setIsLoading(false)
					})
					.catch(() => {
						setIsLoading(false)
					})
			}
		}
	}, [slugDebounce, slug])

	const [formSchema, setFormSchema] = useState({
		schema_type: '',
		schema_script: '',
		post_id: [],
		page_id: [],
	})
	const getPost = async (id) => {
		const result = await getPostById(id)

		const res = result?.data
		setFormAdd({
			...res,
			id: res?._id,
			thumb: res.thumb,
			thumbAlt: res.thumbAlt || '',
			status: res.status,
			categories: res.categories?.map((i) => i._id),
			tags: res.tags?.map((i) => i._id),
			keyword: res.keyword,
			isNoFollow: res?.isNoFollow,
			isNoIndex: res?.isNoIndex,
			isTOC: res?.isTOC,
			post_schemaid: res.post_schemaid?.map((i) => i._id),
			faqPage: res?.faqPage || {},
			questions: res?.questions,
		})

		setIsNoIndex(res?.isNoIndex)
		setIsNoFollow(res?.isNoFollow)
		setIsTOC(res?.isTOC)
		const KeyFocus = res?.keyword?.map((item, i) => ({
			id: new Date().getTime() + i,
			text: item,
		}))
		setFocusKeyword(KeyFocus)
		// setFormAdd(result?.data);

		// const dataKeyWord = result?.data?.keyword;

		// setFocusKeyword(dataKeyWord);
	}
	// useEffect(() => {
	//   if (id) {
	//     getPost(id);
	//   }
	// }, [id]);
	const handleChangeEditorDescription = async (value, editor) => {
		const p = document.createElement('p')
		p.innerHTML = value
		const description = p.innerText
		formAdd.description = description
	}
	const handleChangeEditorContent = async (value, editor) => {
		// const p = document.createElement("p");
		// p.innerHTML = value;
		// const content = p.innerText;
		console.log(value)
		formAdd.content = value
	}
	const getSchemas = () => {
		getAllSchemas().then((res) => {
			setSchemas(res)
		})
	}
	const convertHtmlText = (htmlText) => {
		if (htmlText && htmlText.length > 0) {
			let strText =
				new DOMParser().parseFromString(htmlText, 'text/html')
					.documentElement.textContent || ''
			return strText
		}
		return ''
	}
	useEffect(() => {
		setIsLoading(true)
		if (!authUser) {
			notification['error']({
				message: 'System error',
				description: 'Vui lòng đăng nhập lại',
			})
			setIsLoading(false)
		}
		if (slug) {
			getPostBySlug(slug)
				.then((res) => {
					setFormAdd({
						...res,
						id: res?._id,
						thumb: res.thumb,
						thumbAlt: res.thumbAlt || '',
						status: res.status,
						categories: res.categories?.map((i) => i._id),
						tags: res.tags?.map((i) => i._id),
						keyword: res.keyword,
						isNoFollow: res?.isNoFollow,
						isNoIndex: res?.isNoIndex,
						isTOC: res?.isTOC,
						post_schemaid: res.post_schemaid?.map((i) => i._id),
						userid: res.userid?.id,
						faqPage: res?.faqPage || {},
						questions: res?.questions,
					})
					setIsNoIndex(res?.isNoIndex)
					setIsNoFollow(res?.isNoFollow)
					setIsTOC(res?.isTOC)
					const KeyFocus = res?.keyword?.map((item, i) => ({
						id: new Date().getTime() + i,
						text: item,
					}))
					setFocusKeyword(KeyFocus)
					setIsLoading(false)
				})
				.catch((error) => {
					notification['error']({
						message: 'System error',
						description: error,
					})
					setIsLoading(false)
				})
		}
		// getPostXML().then((res) => {
		//   if (res && res.length > 0) {
		//     setPosts(res);
		//   }
		// });
		// getAllSchemas()
		//   .then((res) => {
		//     const formatRes = res.map((item) => {
		//       item.schema_script = convertHtmlText(item.schema_script);
		//       return item;
		//     });
		//     setSchemaList(formatRes);
		//     setIsLoading(false);
		//     //return getAllTaxonomies();
		//   })
		//   // .then((res) => {
		//   //   setTaxList(res);
		//   //   //setIsLoading(false);
		//   // })
		//   .catch((error) => {
		//     setIsLoading(false);\
		//     notification["error"]({
		//       message: "System error",
		//       description: error,
		//     });
		//   });
		getDomains()
		getCate()
		getTags()
		getUsers()
	}, [])
	const getDomains = async () => {
		const result = await getpagingDomains(10000, 1, '')
		if (result) {
			setDomain(result?.data)
		}
	}
	const getUsers = async () => {
		const result = await getAllUsers()
		if (result) {
			setUser(result)
		}
	}
	const getCate = () => {
		getAllCate().then((cateList) => {
			setCateList(cateList)
			setIsLoading(false)
		})
	}
	const getTags = () => {
		getAllTag().then((tagList) => {
			setTagList(tagList)
			setIsLoading(false)
		})
	}

	const handleAddNewTag = async (listTagForm) => {
		let newTagList = await Promise.all(
			listTagForm &&
				listTagForm.map(async (item) => {
					if (!tagList.tags.find((a) => a._id === item)) {
						let dataReq = {
							name: item,
							slug: toSlug(item),
							id: Math.random(),
						}
						await addTag(dataReq).then((tag) => {
							item = tag?.newTag?._id
						})
						return item
					} else {
						return item
					}
				})
		)
		return newTagList
	}

	const handleOpenChooseImage = () => {
		getListImageBunny().then((res) => {
			if (res.HttpCode === 401) {
				setMediaList()
			} else {
				setMediaList(res)
			}
		})
		setIsModalChooseImage(true)
	}

	const handleAddNewTax = async (listTaxForm) => {
		let newTaxList = await Promise.all(
			listTaxForm &&
				listTaxForm.map(async (item) => {
					if (!cateList?.categories?.find((a) => a._id === item)) {
						let dataReq = {
							name: item,
							slug: toSlug(item),
							id: Math.random(),
							isNoFlow: true,
							isNoIndex: true,
							isShow: true,
							description: item,
						}
						await addCategory(dataReq).then((tax) => {
							item = tax?.newCategory?._id
						})
						return item
					} else {
						return item
					}
				})
		)
		return newTaxList
	}

	const handleChooseImage = () => {
		setFormAdd({
			...formAdd,
			thumb: `https://gofiber.b-cdn.net/Admin/${imageChoose}`,
		})
		setIsModalChooseImage(false)
	}

	const onSave = async () => {
		if (!formAdd?.slug) {
			return notification['error']({
				message: 'Bạn chưa điền đường dẫn tĩnh!',
				description: error,
			})
		}
		let newTaxList = await handleAddNewTax(formAdd.categories)
		let newTagList = await handleAddNewTag(formAdd.tags)
		setIsLoading(true)

		const data = {
			type: 'post',
			title: formAdd.title,
			slug: formAdd.slug,
			thumb: formAdd.thumb,
			thumbAlt: formAdd.thumbAlt,
			guid: '',
			status: formAdd.status,
			domains: formAdd.domains,
			// commentStatus: false,
			categories: newTaxList,
			tags: newTagList,
			views: formAdd.views,
			likes: formAdd.likes,
			description: formAdd.description,
			content: formAdd.content,
			keyword: formAdd.keyword,
			isNoFollow: formAdd.isNoFollow,
			isNoIndex: formAdd.isNoIndex,
			isTOC: formAdd.isTOC,
			userid: formAdd.userid,
			questions: formAdd.questions,
		}

		if (formAdd._id !== '') {
			// update
			editPost(formAdd._id, data)
				.then((res) => {
					if (res.success === true) {
						// notification["success"]({
						//   message: "Notification",
						//   description: "Create post successfully!",
						// });
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					//   history.push('/posts');
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else if (id) {
			// update
			editPost(id, data)
				.then((res) => {
					if (res.success === true) {
						// notification["success"]({
						//   message: "Notification",
						//   description: "Create post successfully!",
						// });
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					//   history.push('/posts');
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else {
			//Lưu
			// return;
			setIsLoading(true)
			createPost(data)
				.then((res) => {
					if (res.status === -2) {
						throw new Error(res.error)
					}
					setIsLoading(false)
					notification['success']({
						message: 'Notification',
						description: 'Create post successfully!',
					})
					history.push('/posts')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		}
	}
	const onBack = () => {
		history.goBack()
	}
	const handleGenerateSlug = () => {
		// setFormAdd(data => {
		//   data[slug] = toSlug(data.title);
		//   return data;
		// });
		setFormAdd({
			...formAdd,
			slug: toSlug(formAdd.title),
		})
	}

	const onInputChange = async (e) => {
		formAdd[e.target.name] = e.target.value
		setFormAdd(formAdd)

		if (e.target.name === 'title') {
			setFormAdd({
				...formAdd,
				thumbAlt: e.target.value,
			})
		}

		let file = e.target.files ? e.target.files[0] : null

		if (file) {
			uploadFileToBunny(file).then((res) => {
				if (res.HttpCode === 201) {
					setFormAdd({
						...formAdd,
						[e.target.name]:
							'https://gofiber.b-cdn.net/Admin/' + file.name,
					})
				}
			})
		}
	}
	//domain
	const onDomainChange = (value) => {
		setFormAdd({
			...formAdd,
			domains: value,
		})
	}

	const onUserChange = (value) => {
		setFormAdd({
			...formAdd,
			userid: value,
		})
	}

	const onCheckboxChange = async (e) => {
		formAdd[e.target.name] = !formAdd[e.target.name]
		if (e.target.name === 'isNoIndex') setIsNoIndex(!isNoIndex)
		if (e.target.name === 'isNoFollow') setIsNoFollow(!isNoFollow)
		if (e.target.name === 'isTOC') setIsTOC(!isTOC)

		// setIsNoIndex(!isNoIndex);
		// console.log(`formAdd`, formAdd);
		// console.log(e.target.checked);
	}
	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)

			reader.onload = () => resolve(reader.result)

			reader.onerror = (error) => reject(error)
		})
	const onSchemaInputChange = (e) => {
		setFormSchema({
			...formSchema,
			[e.target.name]: e.target.value,
		})
	}
	const onChangeStatus = (e) => {
		setFormAdd({
			...formAdd,
			status: e,
		})
	}

	const onDescriptionChange = (value) => {
		setFormAdd({
			...formAdd,
			description: value,
		})
	}

	const onSchemaChange = (value) => {
		setFormAdd({
			...formAdd,
			post_schemaid: value,
		})
	}
	const onTaxChange = (value) => {
		setFormAdd({
			...formAdd,
			categories: value,
		})
	}

	const onTagChange = (value) => {
		setFormAdd({
			...formAdd,
			tags: value,
		})
		// console.log(`form`, formAdd);
	}
	const onPostChange = (e) => {
		// console.log(e);
		setFormSchema({
			...formSchema,
			_id: e,
		})
	}
	const addNewSchema = () => {
		addSchema({
			...formSchema,
			post_id: formSchema.post_id.join(','),
		})
			.then((res) => {
				success()
				setIsModalVisible(false)
				getSchemas()
				setFormSchema({
					schema_type: '',
					schema_script: '',
					post_id: [],
				})
			})
			.catch((err) => {
				error()
			})
	}

	const removeSchema = () => {
		// console.log('formSchema.post_schemaid: ', formAdd.post_schemaid);
		if (formAdd.post_schemaid && formAdd.post_schemaid.length) {
			formAdd.post_schemaid.split(',').forEach((id) => {
				deleteSchema(id)
					.then((res) => {
						getSchemas()
						success()
					})
					.catch((er) => {
						error()
					})
			})
			setTimeout(() => {
				setFormAdd({
					...formAdd,
					post_schemaid: '',
				})
			}, 1000)
			setConfirmModalVisible(false)
		}
	}

	const onPressKeyfocus = (e) => {
		if (e.key === 'Enter' && e.target.value) {
			setFocusKeyword([
				...keyword,
				{ id: new Date().getTime(), text: e.target.value },
			])
			const txtValues = keyword.map((item) => item.text)
			setFormAdd({
				...formAdd,
				keyword: [...txtValues, e.target.value],
			})
			e.target.value = ''
		}
	}
	const onRemoveKeyfocus = (id) => {
		const rates = keyword.filter((item) => item.id !== id)
		setFocusKeyword(rates)
		const txtValues = rates.map((item) => item.text)
		setFormAdd({
			...formAdd,
			keyword: txtValues,
		})
	}

	const breadCrumbTitle = id ? 'Sửa' : 'Thêm mới'

	function handleQuestionChange(index, event, action) {
		const newQuestions = [...formAdd.questions]
		if (action === 'delete') {
			newQuestions.splice(index, 1)
		} else {
			newQuestions[index][event.target.name] = event.target.value
		}
		// setQuestions(newQuestions);
		setFormAdd({
			...formAdd,
			questions: newQuestions,
		})
	}

	function addQuestion() {
		const newQuestions = [
			...formAdd.questions,
			{ question: '', answer: '' },
		]
		// setQuestions(newQuestions);
		setFormAdd({
			...formAdd,
			questions: newQuestions,
		})
	}
	return (
		<>
			<Spin spinning={isLoading}>
				<div className='page-content'>
					<BreadCrumb
						title={breadCrumbTitle}
						pageTitle='Bài viết'
						slug='posts'
					/>
					<div style={{ marginLeft: '10px' }}>
						<Form onSubmit={onSave}>
							<Row>
								<Col lg={12}>
									<Label className='mb-1'>Domains</Label>
									<FormGroup>
										<Select
											mode='multiple'
											style={{ width: '100%' }}
											placeholder='Please select'
											onChange={onDomainChange}
											value={formAdd.domains || []}
										>
											{domain &&
												domain?.map((item) => (
													<Option key={item._id}>
														{item?.host}{' '}
													</Option>
												))}
										</Select>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Tiêu đề
										</Label>
										<Input
											id='title'
											name='title'
											placeholder='Title'
											type='title'
											defaultValue={formAdd.title || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
								<Col>
									<Label
										className='mb-1'
										for='slug'
									>
										Đường dẫn tĩnh
									</Label>
									<Row>
										<Col
											lg={2}
											className='mr-3 mb-2'
										>
											<Button
												type='primary'
												onClick={handleGenerateSlug}
												className='m-auto'
											>
												Tạo đường dẫn
											</Button>
										</Col>
										<Col lg={9}>
											<FormGroup>
												<Input
													id='slug'
													name='slug'
													placeholder='Slug'
													type='slug'
													defaultValue={formAdd.slug || ''}
													onChange={onInputChange}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col lg={3}>
									<FormGroup>
										<Label
											className='mb-1'
											for='views'
										>
											Views
										</Label>
										<Input
											id='views'
											name='views'
											placeholder='Views'
											type='number'
											defaultValue={formAdd.views || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
								<Col lg={3}>
									<FormGroup>
										<Label
											className='mb-1'
											for='likes'
										>
											Likes
										</Label>
										<Input
											id='likes'
											name='likes'
											placeholder='Likes'
											type='number'
											defaultValue={formAdd.likes || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
								<Col lg={2}>
									<FormGroup>
										<Label
											className='mb-1'
											for='isNoIndex'
										>
											No Index{' '}
										</Label>
										<div>
											<Checkbox
												id='isNoIndex'
												name='isNoIndex'
												// defaultChecked={formAdd.isNoIndex}
												checked={isNoIndex}
												onChange={onCheckboxChange}
											/>
										</div>
									</FormGroup>
								</Col>
								<Col lg={2}>
									<FormGroup>
										<Label
											className='mb-1'
											for='isNoFollow'
										>
											No Follow{' '}
										</Label>
										<div>
											<Checkbox
												id='isNoFollow'
												name='isNoFollow'
												type='checkbox'
												checked={isNoFollow}
												onChange={onCheckboxChange}
											/>
										</div>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col lg={12}>
									<FormGroup>
										<Label
											className='mb-1'
											for='description'
										>
											Mô tả
										</Label>

										<Editor
											apiKey={
												'i90dbv242azw8c5um2zf4pydfpl3qd9xrqubimrguvszeaxq'
											}
											onInit={(evt, editor) =>
												(editorDescriptionRef.current = editor)
											}
											onEditorChange={handleChangeEditorDescription}
											initialValue={formAdd?.description || ''}
											// value={formAdd?.description}
											init={{
												height: 200,
												menubar: false,
												file_picker_callback: function (
													cb,
													value,
													meta
												) {
													var input = document.createElement('input')
													input.setAttribute('type', 'file')
													input.setAttribute('accept', 'image/*')
													input.onchange = function () {
														var file = this.files[0]
														var reader = new FileReader()
														reader.onload = function () {
															var id =
																'blobid1' + new Date().getTime()
															var blobCache =
																editorDescriptionRef.current
																	.editorUpload.blobCache
															var base64 = reader.result.split(',')[1]
															var blobInfo = blobCache.create(
																id,
																file,
																base64
															)
															blobCache.add(blobInfo)
															/* call the callback and populate the Title field with the file name */
															cb(blobInfo.blobUri(), {
																title: file.name,
															})
														}
														reader.readAsDataURL(file)
													}
													input.click()
												},
												paste_data_images: true,
												image_title: true,

												automatic_uploads: true,
												file_picker_types: 'image',
												plugins: [
													'advlist',
													'lists',
													'link',
													'image',
													'charmap',
													'preview',
													'anchor',
													'searchreplace',
													'visualblocks',
													'code',
													'fullscreen',
													'insertdatetime',
													'media',
													'table',
													'code',
													'help',
													'wordcount',
													'image',
												],

												toolbar:
													'undo redo | blocks | ' +
													'bold italic forecolor | alignleft aligncenter ' +
													'alignright alignjustify | bullist numlist outdent indent | ' +
													'removeformat | link image | code | gallery ',
												content_style:
													'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
												link_rel_list: [
													{ title: 'Nothing', value: '' },
													{
														title: 'No Referrer',
														value: 'noreferrer',
													},
													{ title: 'No Follow', value: 'nofollow' },
													{ title: 'Sponsored', value: 'sponsored' },
													{ title: 'UGC', value: 'ugc' },
												],
												setup: function (editor) {
													editor.ui.registry.addButton('gallery', {
														text: 'My Gallery',
														icon: 'gallery',
														onAction: (_) => {
															getListImageBunny().then((res) => {
																let listImage = res
																// console.log(listImage, 'listImage');
																editor.windowManager.open({
																	title: 'My Gallery',
																	size: 'large',
																	body: {
																		type: 'panel',
																		items: [
																			{
																				type: 'htmlpanel',
																				html: `<div style="display:flex; justify-content: center; align-items: center; gap: 40px">
                  <div style="border: 1px solid black; width: 50%; height: 40px; border-radius: 5px; display: flex; justify-content: center; align-items: center">
                    <input id="searchInput" placeholder="Enter image name" style="width:80%"/>
                  </div>
                  <button id="searchButton" style="background-color: blue; color: white; padding: 10px; border-radius: 5px; cursor: pointer">Tìm kiếm</button>
                </div>`,
																			},
																			{
																				type: 'htmlpanel',
																				html: `
                  <div style="display:flex;flex-wrap: wrap;height:500px;justify-content:center" class="list-image">
                    ${listImage
											.map(
												(img) =>
													`<div class="gallery-div" style="margin:12px" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);">
                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
                              <div style="position: absolute; top: 0; right: 0;">
                                <button class="delete-button" data-image="${img.ObjectName}" style="background-color: red; color: white; padding: 5px; border-radius: 50%; cursor: pointer;">x</button>
                              </div>
                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                            </div>
                          </div>`
											)
											.join('')}
                  </div>
                  <input type="hidden" id="imagePick" value=""/>
                `,
																			},
																		],
																	},
																	buttons: [
																		{
																			type: 'cancel',
																			name: 'closeButton',
																			text: 'Cancel',
																		},
																		{
																			type: 'submit',
																			name: 'submitButton',
																			text: 'Insert To Editor',
																			primary: true,
																		},
																	],
																	onSubmit: function (api) {
																		var data = api.getData()
																		let source =
																			document.getElementById(
																				'imagePick'
																			)

																		editor.focus()
																		editor.selection.setContent(
																			`<img src="https://gofiber.b-cdn.net/Admin/${source.value}" />`
																		)
																		api.close()
																	},
																})

																// Xử lý sự kiện click của nút Tìm kiếm
																const searchButton =
																	document.getElementById(
																		'searchButton'
																	)
																searchButton.addEventListener(
																	'click',
																	function () {
																		const searchInput =
																			document.getElementById(
																				'searchInput'
																			).value
																		const filteredList =
																			listImage.filter((img) =>
																				img.ObjectName.includes(
																					searchInput
																				)
																			)

																		const galleryDiv =
																			document.querySelector(
																				'.list-image'
																			)
																		galleryDiv.innerHTML =
																			filteredList
																				.map(
																					(img) =>
																						`<div class="gallery-div" style="margin:12px" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);">
                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
              
                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                            </div>
                          </div>`
																				)
																				.join('')
																	}
																)

																// Xử lý sự kiện click của nút x (xóa hình ảnh)
																const deleteButtons =
																	document.querySelectorAll(
																		'.delete-button'
																	)
																deleteButtons.forEach((button) => {
																	button.addEventListener(
																		'click',
																		function () {
																			const imageToDelete =
																				button.dataset.image
																			// Xử lý logic xóa hình ảnh ở đây

																			deleteImageBunny(imageToDelete)
																				.then((res) => {
																					notification['success']({
																						message: 'Notification',
																						description: `Xóa hình ${imageToDelete} thành công`,
																					})
																					getListImageBunny().then(
																						(res) => {
																							listImage = res
																							const galleryDiv =
																								document.querySelector(
																									'.list-image'
																								)
																							galleryDiv.innerHTML =
																								res
																									.map(
																										(img) =>
																											`<div class="gallery-div" style="margin:12px" >
                                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
                                              <div style="position: absolute; top: 0; right: 0;">
                                <button class="delete-button" data-image="${img.ObjectName}" style="background-color: red; color: white; padding: 5px; border-radius: 50%; cursor: pointer;">x</button>
                              </div>
                                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                                            </div>
                                          </div>`
																									)
																									.join('')
																						}
																					)
																				})
																				.catch((err) => {
																					notification['error']({
																						message: 'Notification',
																						description: `Xóa hình ${imageToDelete} thất bại`,
																					})
																				})
																			// Sau khi xóa hình ảnh, có thể cập nhật lại danh sách hình ảnh hiển thị bằng cách gọi lại getListImageBunny() và cập nhật galleryContainer
																		}
																	)
																})
															})
														},
													})
												},
											}}
										/>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<FormGroup>
										<Label
											className='mb-1'
											for='content'
										>
											Nội dung
										</Label>
										<Editor
											apiKey={
												'i90dbv242azw8c5um2zf4pydfpl3qd9xrqubimrguvszeaxq'
											}
											onInit={(evt, editor) =>
												(editorContentRef.current = editor)
											}
											onEditorChange={handleChangeEditorContent}
											initialValue={formAdd?.content || ''}
											// value={formVal?.description}
											init={{
												height: 500,
												// menubar: false,
												file_picker_callback: function (
													cb,
													value,
													meta
												) {
													var input = document.createElement('input')
													input.setAttribute('type', 'file')
													input.setAttribute('accept', 'image/*')
													input.onchange = function () {
														var file = this.files[0]

														var reader = new FileReader()
														reader.onload = function () {
															// var id = "blobid" + new Date().getTime();
															// var blobCache =
															//   editorContentRef.current.editorUpload.blobCache;
															// var base64 = reader.result.split(",")[1];
															// var blobInfo = blobCache.create(id, file, base64);
															// blobCache.add(blobInfo);
															uploadFileToBunny(file).then((res) => {
																if (res.HttpCode === 201) {
																	cb(
																		`https://gofiber.b-cdn.net/Admin/${file.name.replace(
																			/\s/g,
																			'-'
																		)}`,
																		{ title: file.name }
																	)
																}
															})
															/* call the callback and populate the Title field with the file name */
														}
														reader.readAsDataURL(file)
													}
													input.click()
												},
												paste_data_images: true,
												image_title: true,
												image_description: true,
												image_caption: true,
												automatic_uploads: true,
												file_picker_types: 'image',
												plugins: [
													'advlist',
													'lists',
													'link',
													'image',
													'charmap',
													'preview',
													'anchor',
													'searchreplace',
													'visualblocks',
													'code',
													'fullscreen',
													'insertdatetime',
													'media',
													'table',
													'code',
													'help',
													'wordcount',
													'image',
													'codesample',
													'table',
												],
												menubar:
													'file edit view insert format tools table help',
												codesample_global_prismjs: true,
												codesample_languages: [
													{ text: 'HTML/XML', value: 'markup' },
													{ text: 'JavaScript', value: 'javascript' },
													{ text: 'CSS', value: 'css' },
													{ text: 'PHP', value: 'php' },
													{ text: 'Ruby', value: 'ruby' },
													{ text: 'Python', value: 'python' },
													{ text: 'Java', value: 'java' },
													{ text: 'C', value: 'c' },
													{ text: 'C#', value: 'csharp' },
													{ text: 'C++', value: 'cpp' },
													{ text: 'Bash', value: 'bash' },
												],
												toolbar1:
													'undo redo | blocks | ' +
													'bold italic forecolor | alignleft aligncenter ' +
													'alignright alignjustify | bullist numlist outdent indent | ' +
													'removeformat | link image | code | gallery | codesample ',
												toolbar2:
													'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
												content_style:
													'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } ',
												link_rel_list: [
													{ title: 'Nothing', value: '' },
													{
														title: 'No Referrer',
														value: 'noreferrer',
													},
													{ title: 'No Follow', value: 'nofollow' },
													{ title: 'Sponsored', value: 'sponsored' },
													{ title: 'UGC', value: 'ugc' },
												],
												setup: function (editor) {
													editor.ui.registry.addButton('gallery', {
														text: 'My Gallery',
														icon: 'gallery',
														onAction: (_) => {
															getListImageBunny().then((res) => {
																let listImage = res
																// console.log(listImage, "listImage");
																editor.windowManager.open({
																	title: 'My Gallery',
																	size: 'large',
																	body: {
																		type: 'panel',
																		items: [
																			{
																				type: 'htmlpanel',
																				html: `<div style="display:flex; justify-content: center; align-items: center; gap: 40px">
                  <div style="border: 1px solid black; width: 50%; height: 40px; border-radius: 5px; display: flex; justify-content: center; align-items: center">
                    <input id="searchInput" placeholder="Enter image name" style="width:80%"/>
                  </div>
                  <button id="searchButton" style="background-color: blue; color: white; padding: 10px; border-radius: 5px; cursor: pointer">Tìm kiếm</button>
                </div>`,
																			},
																			{
																				type: 'htmlpanel',
																				html: `
                  <div style="display:flex;flex-wrap: wrap;height:500px;justify-content:center" class="list-image">
                    ${listImage
											.map(
												(img) =>
													`<div class="gallery-div" style="margin:12px" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);">
                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
                              <div style="position: absolute; top: 0; right: 0;">
                                <button class="delete-button" data-image="${img.ObjectName}" style="background-color: red; color: white; padding: 5px; border-radius: 50%; cursor: pointer;">x</button>
                              </div>
                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                            </div>
                          </div>`
											)
											.join('')}
                  </div>
                  <input type="hidden" id="imagePick" value=""/>
                `,
																			},
																		],
																	},
																	buttons: [
																		{
																			type: 'cancel',
																			name: 'closeButton',
																			text: 'Cancel',
																		},
																		{
																			type: 'submit',
																			name: 'submitButton',
																			text: 'Insert To Editor',
																			primary: true,
																		},
																	],
																	onSubmit: function (api) {
																		var data = api.getData()
																		let source =
																			document.getElementById(
																				'imagePick'
																			)
																		editor.focus()
																		editor.selection.setContent(
																			`<img src="https://gofiber.b-cdn.net/Admin/${source.value}" />`
																		)
																		api.close()
																	},
																})

																// Xử lý sự kiện click của nút Tìm kiếm
																const searchButton =
																	document.getElementById(
																		'searchButton'
																	)
																searchButton.addEventListener(
																	'click',
																	function () {
																		const searchInput =
																			document.getElementById(
																				'searchInput'
																			).value
																		const filteredList =
																			listImage.filter((img) =>
																				img.ObjectName.includes(
																					searchInput
																				)
																			)

																		const galleryDiv =
																			document.querySelector(
																				'.list-image'
																			)
																		galleryDiv.innerHTML =
																			filteredList
																				.map(
																					(img) =>
																						`<div class="gallery-div" style="margin:12px" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);">
                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
                              
                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                            </div>
                          </div>`
																				)
																				.join('')
																	}
																)

																// Xử lý sự kiện click của nút x (xóa hình ảnh)
																const deleteButtons =
																	document.querySelectorAll(
																		'.delete-button'
																	)
																deleteButtons.forEach((button) => {
																	button.addEventListener(
																		'click',
																		function () {
																			const imageToDelete =
																				button.dataset.image
																			// Xử lý logic xóa hình ảnh ở đây

																			deleteImageBunny(imageToDelete)
																				.then((res) => {
																					notification['success']({
																						message: 'Notification',
																						description: `Xóa hình ${imageToDelete} thành công`,
																					})
																					getListImageBunny().then(
																						(res) => {
																							listImage = res
																							const galleryDiv =
																								document.querySelector(
																									'.list-image'
																								)
																							galleryDiv.innerHTML =
																								res
																									.map(
																										(img) =>
																											`<div class="gallery-div" style="margin:12px" >
                                            <div style="width: 150px;height: 150px;border-radius: 10px;overflow: hidden;cursor: pointer;position: relative;color: #f0f0f0;box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);" onclick="document.getElementById('imagePick').value = '${img.ObjectName}'; this.style.border='5px solid green';">
                                              <img src="https://gofiber.b-cdn.net/Admin/${img.ObjectName}" alt="Avatar" style="position: absolute;object-fit: cover;width: 100%;height: 100%;top: 0;left: 0;">
                                              <div style="position: absolute; top: 0; right: 0;">
                                <button class="delete-button" data-image="${img.ObjectName}" style="background-color: red; color: white; padding: 5px; border-radius: 50%; cursor: pointer;">x</button>
                              </div>
                                              <div style="position: absolute; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); padding: 5px; width: 100%; color: white; text-align: center;">${img.ObjectName}</div>
                                            </div>
                                          </div>`
																									)
																									.join('')
																						}
																					)
																				})
																				.catch((err) => {
																					notification['error']({
																						message: 'Notification',
																						description: `Xóa hình ${imageToDelete} thất bại`,
																					})
																				})
																			// Sau khi xóa hình ảnh, có thể cập nhật lại danh sách hình ảnh hiển thị bằng cách gọi lại getListImageBunny() và cập nhật galleryContainer
																		}
																	)
																})
															})
														},
													})
												},
											}}
										/>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<FormGroup>
										<Label
											className='mb-1'
											for='status'
										>
											Trạng thái
										</Label>
										<Select
											value={formAdd.status}
											// key={formAdd.status}
											style={{ width: '100%' }}
											onChange={onChangeStatus}
										>
											<Option
												label='Đăng ngay'
												key={1}
												value={1}
											>
												Đăng ngay
											</Option>
											<Option
												label='Nháp'
												key={-1}
												value={-1}
											>
												Nháp
											</Option>
											<Option
												label='Chờ xét duyệt'
												key={0}
												value={0}
											>
												Chờ xét duyệt
											</Option>
										</Select>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<Label className='mb-1'>Chuyên mục:</Label>
									<FormGroup>
										<Select
											mode='tags'
											style={{ width: '100%' }}
											placeholder='Please select'
											onChange={onTaxChange}
											value={formAdd.categories || []}
										>
											{cateList &&
												cateList.categories?.map((item) => (
													<Option key={item._id}>
														{item?.name}{' '}
														{item.parent ? (
															<>({item.parent.name})</>
														) : null}{' '}
													</Option>
												))}
										</Select>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<Label className='mb-1'>Thẻ:</Label>
									<FormGroup>
										<Select
											mode='tags'
											style={{ width: '100%' }}
											placeholder='Please select'
											onChange={onTagChange}
											value={formAdd.tags || []}
										>
											{tagList &&
												tagList.tags?.map((item) => (
													<Option key={item._id}>
														{item?.name}{' '}
													</Option>
												))}
										</Select>
									</FormGroup>
								</Col>
								<Col lg={12}></Col>
								<Col lg={12}>
									<FormGroup>
										<Label
											className='mb-1'
											for='dealer_link'
										>
											Focus keyword
										</Label>
										<Input
											id='keyword'
											name='keyword'
											placeholder='Nhập xong nhấp enter'
											type='keyword'
											onKeyPress={onPressKeyfocus}
										/>
									</FormGroup>
									{keyword?.map((item, i) => (
										<TagComp
											key={item.id}
											content={item}
											onRemove={onRemoveKeyfocus}
											id={item.id}
										/>
									))}
								</Col>
								<Col lg={12}>
									<Label className='mb-1'>Người thêm bài</Label>
									<FormGroup>
										<Select
											style={{ width: '100%' }}
											placeholder='Please select'
											onChange={onUserChange}
											value={formAdd.userid || ''}
											disabled={authUser?.role?.name !== 'Admin'}
										>
											{user &&
												user?.map((item) => (
													<Option key={item.id}>
														{item?.firstName} {item?.lastName}
													</Option>
												))}
										</Select>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<FormGroup>
										<Label
											className='mb-1'
											for='thumb'
										>
											Hình ảnh
										</Label>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<label
												className='custom-file-upload'
												style={{ height: '100%' }}
											>
												<Input
													id='thumb'
													name='thumb'
													placeholder='Image'
													accept='image/*'
													type='file'
													defaultValue={formAdd.thumb || ''}
													onChange={onInputChange}
												/>
												Thêm hình ảnh
											</label>
											<label
												className='custom-file-upload'
												style={{ height: '100%' }}
											>
												<Input
													type='file'
													onClick={(e) => {
														e.preventDefault()
														handleOpenChooseImage()
													}}
												/>
												Chọn ảnh từ thư viện
											</label>
										</div>
										{formAdd.thumb && formAdd.thumb !== '' && (
											<Col
												lg={2}
												className='position-relative box-edit-image'
											>
												<img
													src={formAdd.thumb}
													alt={formAdd.thumb}
													style={{ width: '100%' }}
												/>
												<div className='wrapper-edit-image'></div>
												<button
													onClick={() => setIsModalEditImage(true)}
													className='button-edit-image'
													type='button'
												>
													<MdOutlineEdit />
												</button>
											</Col>
										)}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<Label> FAQ page</Label>
									<FormGroup rules={[{ required: true }]}>
										{formAdd.questions.map((question, index) => (
											<>
												<Row>
													<Col lg={11}>
														<Label
															className='mb-1'
															for='question'
														>
															Câu hỏi {index + 1}
														</Label>
														<Input
															name='question'
															placeholder={`Câu hỏi ${index + 1}`}
															value={question.question}
															onChange={(event) =>
																handleQuestionChange(index, event)
															}
														/>
														<Label
															className='mb-1'
															for='question'
														>
															Câu trả lời {index + 1}
														</Label>
														<Input
															type='textarea'
															rows='6'
															name='answer'
															placeholder={`Trả lời câu hỏi ${
																index + 1
															}`}
															value={question.answer}
															onChange={(event) =>
																handleQuestionChange(index, event)
															}
														/>
													</Col>

													<Col lg={1}>
														<span
															className='delete-question'
															onClick={() =>
																handleQuestionChange(
																	index,
																	null,
																	'delete'
																)
															}
															style={{
																fontSize: '30px',
																display: 'flex',
																marginTop: '20px',
																color: 'red',
																fontWeight: 'bold',
																cursor: 'pointer',
															}}
														>
															X
														</span>
													</Col>
												</Row>
											</>
										))}
										<Button
											onClick={addQuestion}
											style={{
												marginTop: '10px',
												backgroundColor: '#008000',
												color: 'white',
												fontWeight: 'bold',
											}}
										>
											Thêm câu hỏi
										</Button>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					</div>
					<Row>
						<Col style={{ marginLeft: '10px', marginTop: '10px' }}>
							<Button
								style={{ marginRight: '10px' }}
								onClick={onBack}
							>
								Quay lại
							</Button>
							<Button
								type='primary'
								onClick={onSave}
							>
								Lưu
							</Button>
						</Col>
					</Row>
				</div>
			</Spin>

			<Modal
				title='Thêm mới schema'
				okText='Save'
				visible={isModalAddSchemaVisible}
				onOk={addNewSchema}
				onCancel={() => setIsModalVisible(false)}
				width='680px'
			>
				<div>
					<Form>
						<Row>
							<Col lg={6}>
								<FormGroup>
									<Label
										className='mb-1'
										for='schema_type'
									>
										Loại
									</Label>
									<Input
										id='schema_type'
										name='schema_type'
										placeholder='Schema type'
										type='text'
										value={formSchema.schema_type}
										onChange={onSchemaInputChange}
									/>
								</FormGroup>
							</Col>
							<Col lg={6}>
								<FormGroup>
									<Label
										className='mb-1'
										for='schema_script'
									>
										Đoạn mã
									</Label>
									<Input
										id='schema_script'
										name='schema_script'
										placeholder='Schema script'
										type='text'
										value={formSchema.schema_script}
										onChange={onSchemaInputChange}
									/>
								</FormGroup>
							</Col>
							<Col lg={12}>
								<FormGroup>
									<Label
										className='mb-1'
										for='post_id'
									>
										Bài viết
									</Label>
									<Select
										mode='multiple'
										size='large'
										name='post_id'
										id='post_id'
										value={formSchema.post_id}
										onChange={onPostChange}
										placeholder='Posts'
										style={{ width: '100%' }}
									>
										{posts &&
											posts.length > 0 &&
											posts?.map((post) => {
												return (
													<Option
														key={post._id}
														value={post._id}
													>
														{post.title}
													</Option>
												)
											})}
									</Select>
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>

			<Modal
				title='Confirm to delete'
				visible={isConfirmModalVisible}
				onOk={removeSchema}
				onCancel={() => setConfirmModalVisible(false)}
			>
				<p>Are you sure to delete this faq?</p>
			</Modal>

			<Modal
				title='Chỉnh sửa hình ảnh'
				open={isModalEditImage}
				// onOk={removeSchema}
				footer={
					<>
						<Button
							type='primary'
							onClick={() => setIsModalEditImage(false)}
						>
							OK
						</Button>
					</>
				}
				onCancel={() => setIsModalEditImage(false)}
			>
				<Form>
					<FormGroup>
						<Label
							className='mb-1'
							for='post_id'
						>
							Văn bản thay thế
						</Label>
						<Input
							className='mt-2'
							style={{ minHeight: '100px' }}
							type='textarea'
							placeholder='Nhập văn bản thay thế'
							id='thumbAlt'
							name='thumbAlt'
							defaultValue={formAdd.thumbAlt || ''}
							onChange={onInputChange}
						/>
					</FormGroup>
				</Form>
			</Modal>
			<Modal
				title='Chọn ảnh từ thư viện'
				open={isModalChooseImage}
				onOk={handleChooseImage}
				width={1200}
				onCancel={() => setIsModalChooseImage(false)}
			>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						overflow: 'scroll',
						maxHeight: 700,
					}}
				>
					{mediaList?.map((img) => {
						return (
							<Card
								key={img.Guid}
								hoverable
								style={{
									width: 150,
									margin: 16,
									border: `${
										imageChoose === img.ObjectName
											? '3px solid green'
											: '0'
									}`,
								}}
								onClick={() => {
									setImageChoose(img.ObjectName)
								}}
								cover={
									<div style={{ overflow: 'hidden', height: 150 }}>
										<img
											alt='example'
											style={{ height: '100%' }}
											src={`https://gofiber.b-cdn.net/Admin/${img.ObjectName}`}
										/>
									</div>
								}
							>
								<Meta title={img.ObjectName} />
							</Card>
						)
					})}
				</div>
			</Modal>
		</>
	)
}

export default CreateEditPost
