import {
  Pagination,
  Popconfirm,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, InputGroup, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  changeStatusView,
  deleteCV,
  getAllDocRecruitment,
} from "../../helpers/helper";
import { BsFillTrash3Fill } from "react-icons/bs";

const { Option } = Select;

const { Column } = Table;

function RecruitmentManager() {
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seo, setSeo] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(0);

  const getSeo = async (pageSize, pageIndex, searchInput) => {
    try {
      setIsLoading(true);
      const result = await getAllDocRecruitment(
        pageSize,
        pageIndex,
        searchInput
      );
      if (result.statusCode === 1) {
        setSeo(result?.result || []);
        setTotalPageSize(result?.totalDocs || 0);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSeo(pageSize, pageIndex, searchInput);
  }, [pageSize, pageIndex, searchInput]);

  const searchSeo = () => {
    getSeo(pageSize, pageIndex, searchInput);
  };

  const handleChangeStatus = async (id) => {
    await changeStatusView(id);
    getSeo(pageSize, pageIndex, searchInput);
  };

  const confirm = async (item) => {
    try {
      const result = await deleteCV(item?._id, item?.fileUrl);
      if (result.statusCode === 1) {
        notification.success({ message: "Xóa hồ sơ thành công!" });
        getSeo(pageSize, pageIndex, searchInput);
      }
    } catch (error) {
      console.log("error", error);
      notification.success({ message: "Xóa hồ sơ thất bại!" });
    }
  };

  const cancel = (e) => {};

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb
              title="Quản lý hồ sơ ứng tuyển"
              pageTitle="Quản lý hồ sơ ứng tuyển"
            />

            <Row className="mb-3">
              <Col lg="2">
                <div>
                  <InputGroup>
                    <Input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder="Tìm kiếm..."
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="6">
                <Row>
                  <Col
                    style={{ width: "130px" }}
                    lg="6"
                  >
                    <div>
                      <Button onClick={() => searchSeo()}>Tìm kiếm</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Table
                  rowKey="_id"
                  dataSource={seo}
                  pagination={false}
                >
                  <Column
                    title="#"
                    render={(val, rec, index) => {
                      return index + 1;
                    }}
                  />
                  <Column
                    title="Họ và Tên"
                    dataIndex="name"
                    key="name"
                  />
                  <Column
                    title="Vị trí ứng tuyển"
                    dataIndex="position"
                    key="position"
                  />
                  <Column
                    title="Số điện thoại"
                    dataIndex="phoneNumber"
                    key="phoneNumber"
                    render={(item) => `0${item}`}
                  />
                  <Column
                    title="Email"
                    dataIndex="email"
                    key="email"
                  />
                  <Column
                    title="Ngày ứng tuyển"
                    dataIndex="createdAt"
                    key="createdAt"
                    render={(item) => moment(item).format("DD-MM-YYYY")}
                  />
                  <Column
                    title="Trạng thái xem"
                    dataIndex="statusView"
                    key="statusView"
                    render={(item) => {
                      return !item || item === 0 ? (
                        <div>
                          <span
                            style={{
                              backgroundColor: "#fff2e8",
                              padding: "5px 10px",
                              borderRadius: "8px",
                              color: "#d7471e",
                              fontWeight: "normal",
                              border: "1px solid #d7471e",
                            }}
                          >
                            Chưa xem
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            style={{
                              backgroundColor: "#f6ffed",
                              padding: "5px 10px",
                              borderRadius: "8px",
                              color: "#58af34",
                              fontWeight: "normal",
                              border: "1px solid #58af34",
                            }}
                          >
                            Đã xem
                          </span>
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Hồ sơ"
                    dataIndex="fileName"
                    key="fileName"
                    render={(item, record) => {
                      return (
                        <a
                          href={`https://gofiber.b-cdn.net/webp/tuyen-dung/ho-so-ung-tuyen/${record?.fileUrl}`}
                          target="_blank"
                          onClick={() => handleChangeStatus(record?._id)}
                        >
                          {item}
                        </a>
                      );
                    }}
                  />
                  <Column
                    title="Hành động"
                    dataIndex="actions"
                    key="actions"
                    render={(item, record) => {
                      return (
                        <Popconfirm
                          title={
                            <div>
                              <div className="font-bold">Xóa hồ sơ</div>
                              <div>
                                Khi xóa hồ sơ các tệp đính kèm sẽ bị xóa, Bạn
                                đồng ý xóa?
                              </div>
                            </div>
                          }
                          onConfirm={() => confirm(record)}
                          onCancel={cancel}
                          okText="Đồng ý"
                          cancelText="Hủy"
                        >
                          <BsFillTrash3Fill
                            size={20}
                            className="cursor-pointer"
                            style={{ color: "gray" }}
                          />
                        </Popconfirm>
                      );
                    }}
                  />
                </Table>
                <div className="text-right">
                  <Pagination
                    className="mt-4"
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPageIndex(page !== 0 ? page : 1);
                      setPageSize(pageSize);
                    }}
                    showTotal={(total) => `Tổng ${total} hồ sơ`}
                    total={totalPageSize}
                    showSizeChanger
                    defaultCurrent = {1}
                    current={pageIndex}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Spin>
    </React.Fragment>
  );
}

export default RecruitmentManager;
