import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
	createHomeSlideBanner,
	getHomeSlideBanner,
	getHomeSlideBanners,
	updateHomeSlideBanner,
} from '../../helpers/helper'
import {
	Button,
	Checkbox,
	Col,
	Form,
	message,
	notification,
	Row,
	Select,
	Spin,
} from 'antd'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { FormGroup, Input, Label } from 'reactstrap'
import {
	getListImageBunny,
	uploadFileToBunny,
} from '../../helpers/api_bunny'
import { MdOutlineEdit } from 'react-icons/md'
import { error } from '../../Components/Common/message'
const { Option } = Select
// * initial data
const initialData = {
	_id: '',
	title: '',
	userId: '',
	thumbnail: {
		url: '',
		alt: '',
		_id: '',
	},
	link: {
		url: '',
		target: '',
		_id: '',
	},
	order: 0,
	placement: '',
	page: '',
	status: 'visible',
	createdAt: '',
	updatedAt: '',
}

const CreateEditBanner = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [banner, setBanner] = useState(initialData)
	const [formAdd, setFormAdd] = useState(initialData)
	const [isModalChooseImage, setIsModalChooseImage] = useState(false)
	const [imageChoose, setImageChoose] = useState()
	const [mediaList, setMediaList] = useState([])
	const [isModalEditImage, setIsModalEditImage] = useState(false)

	const { id } = useParams()
	const history = useHistory()
	const getBanner = async (id) => {
		const result = await getHomeSlideBanner(id)
		if (result) {
			setBanner(result)
			setFormAdd(result)
		}
	}

	//* form control
	const onSave = async () => {
		setIsLoading(true)
		const data = {
			type: 'post',
			title: formAdd.title,
			thumbnail: formAdd.thumbnail.url,
			thumbnailAlt: formAdd.thumbnail.alt,
			link: formAdd.link.url,
			linkTarget: formAdd.link.target,
			status: formAdd.status,
			order: formAdd.order,
			userid: formAdd.userid,
		}

		if (formAdd._id !== '') {
			// update
			updateHomeSlideBanner(formAdd._id, data)
				.then((res) => {
					if (res.success === true) {
						notification['success']({
							message: 'Notification',
							description: 'Create Banner successfully!',
						})
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					history.push('/home-slide-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else if (id) {
			// update
			updateHomeSlideBanner(id, data)
				.then((res) => {
					if (res.success === true) {
						notification['success']({
							message: 'Notification',
							description: 'Create post successfully!',
						})
						message.success('Lưu thành công')
					}

					setIsLoading(false)
					history.push('/home-slide-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		} else {
			//Lưu
			// return;
			setIsLoading(true)
			createHomeSlideBanner(data)
				.then((res) => {
					if (res.status === -2) {
						throw new Error(res.error)
					}
					setIsLoading(false)
					notification['success']({
						message: 'Notification',
						description: 'Create post successfully!',
					})
					history.push('/home-slide-banner')
				})
				.catch((error) => {
					setIsLoading(false)
					notification['error']({
						message: 'System error',
						description: error,
					})
				})
		}
	}
	const onBack = () => {
		history.goBack()
	}
	const onInputChange = async (e) => {
		formAdd[e.target.name] = e.target.value
		setFormAdd(formAdd)

		if (e.target.name === 'title') {
			setBanner({
				...formAdd,
				title: e.target.value,
			})
		}
		if (e.target.name === 'link') {
			setFormAdd({
				...formAdd,
				link: { url: e.target.value },
			})
		}
		if (e.target.name === 'order') {
			setFormAdd({
				...formAdd,
				order: e.target.value,
			})
		}

		let file = e.target.files ? e.target.files[0] : null

		if (file) {
			uploadFileToBunny(file).then((res) => {
				if (res.HttpCode === 201) {
					setFormAdd({
						...formAdd,
						thumbnail: {
							url: 'https://gofiber.b-cdn.net/Admin/' + file.name,
						},
					})
				}
			})
		}
	}
	const onChangeStatus = (value) => {
		setFormAdd({
			...formAdd,
			status: value,
		})
	}
	const handleChooseImage = () => {
		setBanner({
			...banner,
			thumbnail: `https://gofiber.b-cdn.net/Admin/${imageChoose}`,
		})
		setIsModalChooseImage(false)
	}
	const handleOpenChooseImage = () => {
		getListImageBunny().then((res) => {
			if (res.HttpCode === 401) {
				setMediaList()
			} else {
				setMediaList(res)
			}
		})
		setIsModalChooseImage(true)
	}
	//* useEffects
	useEffect(() => {
		if (id) {
			getBanner(id)
		}
	}, [id])

	const breadCrumbTitle = id ? 'Sửa' : 'Thêm mới'

	//* log variables before rendering ::::

	return (
		<>
			<Spin spinning={isLoading}>
				<div className='page-content'>
					<BreadCrumb
						title={breadCrumbTitle}
						pageTitle='Banner'
						slug='/home-slide-banner'
					/>
					<div className=''>
						<Form onSubmit={onSave}>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Tiêu đề
										</Label>
										<Input
											id='title'
											name='title'
											placeholder='Title'
											type='title'
											defaultValue={formAdd?.title || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Đường dẫn
										</Label>
										<Input
											id='link'
											name='link'
											placeholder='Link'
											type='link'
											defaultValue={formAdd?.link.url || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<FormGroup rules={[{ required: true }]}>
										<Label
											className='mb-1'
											for='title'
										>
											Thứ tự
										</Label>
										<Input
											id='order'
											name='order'
											placeholder='Order'
											type='number'
											defaultValue={formAdd?.order || ''}
											onChange={onInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Col lg={12}>
								<FormGroup>
									<Label
										className='mb-1'
										for='status'
									>
										Trạng thái
									</Label>
									<Select
										value={formAdd?.status}
										// key={formAdd.status}
										style={{ width: '100%' }}
										onChange={onChangeStatus}
									>
										<Option
											label='Visible'
											key={1}
											value={'visible'}
										>
											Visible
										</Option>
										<Option
											label='Hidden'
											key={-1}
											value={'hidden'}
										>
											Hidden
										</Option>
									</Select>
								</FormGroup>
							</Col>
							<Col lg={12}>
								<FormGroup>
									<Label
										className='mb-1'
										for='thumb'
									>
										Hình ảnh
									</Label>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<label
											className='custom-file-upload'
											style={{ height: '100%' }}
										>
											<Input
												id='thumbnail'
												name='thumbnail'
												placeholder='Image'
												accept='image/*'
												type='file'
												defaultValue={formAdd.thumbnail.url || ''}
												onChange={onInputChange}
											/>
											Thêm hình ảnh
										</label>
									</div>
									{formAdd?.thumbnail.url &&
										formAdd?.thumbnail.url !== '' && (
											<Col
												lg={12}
												className='position-relative box-edit-image'
											>
												<img
													src={formAdd?.thumbnail.url}
													alt={formAdd?.thumbnail.alt}
													style={{ width: '100%' }}
												/>
												<div className='wrapper-edit-image'></div>
												<button
													onClick={() => setIsModalEditImage(true)}
													className='button-edit-image'
													type='button'
												>
													<MdOutlineEdit />
												</button>
											</Col>
										)}
								</FormGroup>
							</Col>
							<Row>
								{/* <Col lg={12}>
										<Label className='mb-1'>Người thêm bài</Label>
										<FormGroup>
											<Select
												style={{ width: '100%' }}
												placeholder='Please select'
												onChange={onUserChange}
												value={formAdd.userid || ''}
												disabled={authUser?.role?.name !== 'Admin'}
											>
												{user &&
													user?.map((item) => (
														<Option key={item.id}>
															{item?.firstName} {item?.lastName}
														</Option>
													))}
											</Select>
										</FormGroup>
									</Col> */}
							</Row>
							<Row>
								<Col
									style={{ marginLeft: '10px', marginTop: '10px' }}
								>
									<Button
										style={{ marginRight: '10px' }}
										onClick={onBack}
									>
										Quay lại
									</Button>
									<Button
										type='primary'
										onClick={onSave}
									>
										Lưu
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</Spin>
		</>
	)
}

export default CreateEditBanner
