import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from 'reactstrap';

import { message, Badge, Space, Table, Modal, Select } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  addUser,
  getUser,
  updateUser,
  getAllRole,
  getpagingDomains,
} from '../../helpers/helper';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { deleteImageBunny, uploadFileToBunny } from '../../helpers/api_bunny';
import { MdOutlineEdit } from 'react-icons/md';
const { Option } = Select;
const { Column } = Table;

const success = () => {
  message.success('Thành công');
};

const error = () => {
  message.error('Có lỗi xảy ra. Vui lòng thử lại!');
};

const socialList = [
  'Facebook',
  'Instagram',
  'Twitter',
  'Gapo',
  'Blogger',
  'Linkhay',
  'Reddit',
  'Tumblr',
  'Youtube',
  'Soundcloud',
  'Website',
];
function AddUser() {
  const { id } = useParams();
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const [user, setUser] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [role, setRole] = useState('');
  const history = useHistory();
  const [formVal, setFormVal] = useState({
    username: '',
    name: '',
    password: '',
    role: '',
    domains: [],
    status: 1,
    avatar: '',
    socials: [],
    description: '',
  });

  const getDomains = async () => {
    let domains = await getpagingDomains(99999, 1, '');
    setDomainList(domains.data);
  };
  const getRole = async () => {
    let roleList = await getAllRole();
    setRoleList(roleList.data);
  };
  useEffect(() => {
    if (id && id !== 'new') {
      getUser(id).then(res => {
        setUser(res);
        setFormVal(res);
      });
    }
    getDomains();
    getRole();
  }, [id]);
  const reset = () => {
    setFormVal({
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      role: '',
      domains: [],
      avatar: '',
    });
  };

  const onInputChange = async e => {
    formVal[e.target.name] = e.target.value;
    setFormVal({
      ...formVal,
      [e.target.name]: e.target.value,
    });

    let file = e.target.files ? e.target.files[0] : null;

    if (file) {
      uploadFileToBunny(file).then(res => {
        if (res.HttpCode === 201) {
          setFormVal({
            ...formVal,
            [e.target.name]: 'https://gofiber.b-cdn.net/Admin/' + file.name,
          });
        }
      });
    }
  };

  const onAvatarChange = async e => {
    let file = e.target.files ? e.target.files[0] : null;

    if (file) {
      const fileName = formVal.avatar.split('/');
      deleteImageBunny(fileName[4]);

      uploadFileToBunny(file).then(res => {
        if (res.HttpCode === 201) {
          setFormVal({
            ...formVal,
            [e.target.name]: 'https://gofiber.b-cdn.net/Admin/' + file.name,
          });
        }
      });
    }
  };

  const onRoleChange = e => {
    setFormVal({ ...formVal, role: e });
  };
  const onDomainsChange = value => {
    setFormVal({
      ...formVal,
      domains: value,
    });
  };
  const onStatusChange = value => {
    setFormVal({ ...formVal, status: value });
  };

  const addNewUser = () => {
    setSubmitted(true);

    if (formVal.password?.length < 6) {
      return;
    }
    if (user) {
      updateUser(id, formVal)
        .then(res => {
          // reset();
          success();
          history.push('/users');
        })
        .catch(err => {
          error();
        });
    } else {
      addUser(formVal)
        .then(res => {
          // reset();
          history.push('/users');
          success();
        })
        .catch(err => {
          error();
        });
    }
  };

  const openFilePicker = () => {
    document.getElementById('avatar-change').click();
  };

  function addSocial() {
    setFormVal({
      ...formVal,
      socials: [...(formVal.socials || []), { name: '', link: '' }],
    });
  }

  function handleSocialChange(index, event, type) {
    const newSocials = [...formVal.socials];
    if (type === 'select') {
      newSocials[index]['name'] = event;
    } else if (type === 'delete') {
      newSocials.splice(index, 1);
    } else {
      newSocials[index][event.target.name] = event.target.value;
    }
    setFormVal({
      ...formVal,
      socials: newSocials,
    });
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={user ? 'Sửa thành viên' : 'Thêm thành viên'}
            pageTitle='Quản lý thành viên'
            slug='users'
          />
          <Row className='mb-3'>
            <div className='mb-3'>
              <Link to='/users'>
                <div className='d-flex align-items-center'>
                  <i className='ri-arrow-left-line'></i>
                  <div style={{ marginLeft: '6px' }}>Quay lại</div>
                </div>
              </Link>
            </div>
            <div>
              <div>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className='mb-1' for='firstName'>
                          Họ
                        </Label>
                        <Input
                          id='firstName'
                          name='firstName'
                          placeholder='First name'
                          value={formVal?.firstName}
                          onChange={onInputChange}
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className='mb-1' for='lastName'>
                          Tên
                        </Label>
                        <Input
                          id='lastName'
                          name='lastName'
                          placeholder='First name'
                          value={formVal?.lastName}
                          onChange={onInputChange}
                          type='text'
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className='mb-1' for='username'>
                          Tên đăng nhập
                        </Label>
                        <Input
                          id='username'
                          name='username'
                          placeholder='Nhập tên đăng nhập'
                          type='text'
                          disabled={id !== 'new' ? true : false}
                          value={formVal?.username}
                          onChange={onInputChange}
                          invalid={
                            submitted
                              ? formVal.username.length >= 6
                                ? false
                                : true
                              : false
                          }
                        />
                        <FormFeedback
                          invalid={
                            submitted
                              ? formVal?.username?.length >= 6
                                ? false
                                : true
                              : false
                          }
                        >
                          Abc
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className='mb-1' for='password'>
                          {' '}
                          Mật khẩu
                        </Label>
                        <Input
                          id='password'
                          name='password'
                          placeholder='password'
                          // disabled={
                          //     authUser?.role?.name !==
                          //     "admin"
                          //         ? true
                          //         : false
                          // }
                          value={formVal?.password}
                          onChange={onInputChange}
                          type='password'
                          invalid={
                            !id && submitted
                              ? formVal.password?.length >= 6
                                ? false
                                : true
                              : false
                          }
                        />
                        <FormFeedback
                          invalid={
                            submitted
                              ? formVal?.password?.length >= 6
                                ? false
                                : true
                              : false
                          }
                        >
                          Mật khẩu chứa ít nhất có 6 kí tự
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className='mb-1' for='domains'>
                          Domains
                        </Label>
                        <Select
                          mode='multiple'
                          size='large'
                          name='domains'
                          id='domains'
                          value={formVal?.domains}
                          onChange={onDomainsChange}
                          placeholder='Domains'
                          style={{ width: '100%' }}
                        >
                          {domainList &&
                            domainList?.map((item, index) => {
                              return (
                                <Option value={item._id} key={item._id}>
                                  {item.host}
                                </Option>
                              );
                            })}
                          {/* <Option value="Admin">Quản lý</Option>
                          <Option value="Editor">Biên tập viên </Option>
                          <Option value="Collaborators">Cộng tác viên</Option> */}
                        </Select>
                      </FormGroup>
                    </Col>
                    {authUser?.role?.name === 'Admin' && (
                      <Col lg={6}>
                        <FormGroup>
                          <Label className='mb-1' for='role'>
                            Phân quyền
                          </Label>
                          <Select
                            size='large'
                            name='role'
                            id='role'
                            value={formVal?.role}
                            defaultValue={role}
                            onChange={onRoleChange}
                            placeholder='Role'
                            style={{
                              width: '100%',
                            }}
                          >
                            {roleList &&
                              roleList?.map((item, index) => {
                                return (
                                  <Option value={item._id} key={item._id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            {/* <Option value="Admin">Quản lý</Option>
                          <Option value="Editor">Biên tập viên </Option>
                          <Option value="Collaborators">Cộng tác viên</Option> */}
                          </Select>
                        </FormGroup>
                      </Col>
                    )}

                    <Col lg={6}>
                      <Label className='mb-1' for='social'>
                        Social
                      </Label>
                      <FormGroup>
                        {formVal?.socials?.length > 0 &&
                          formVal?.socials.map((item, index) => (
                            <>
                              <Row
                                className='mt-2'
                                style={{
                                  position: 'relative',
                                }}
                              >
                                <Col lg={3}>
                                  <Select
                                    size='large'
                                    name='name'
                                    placeholder='Social'
                                    style={{
                                      width: '100%',
                                    }}
                                    value={item.name || null}
                                    defaultValue={
                                      formVal?.socials[index]?.name || null
                                    }
                                    onChange={value => {
                                      handleSocialChange(
                                        index,
                                        value,
                                        'select',
                                      );
                                    }}
                                  >
                                    {socialList?.map((item, index) => {
                                      return (
                                        <>
                                          <Option value={item}>{item}</Option>
                                        </>
                                      );
                                    })}
                                  </Select>
                                </Col>
                                <Col lg={8}>
                                  <Input
                                    name='link'
                                    value={item.link}
                                    placeholder='Link social'
                                    defaultValue={
                                      formVal?.socials[index]?.link || null
                                    }
                                    onChange={event =>
                                      handleSocialChange(index, event)
                                    }
                                  />
                                </Col>
                                <Col lg={1}>
                                  <span
                                    onClick={() =>
                                      handleSocialChange(index, null, 'delete')
                                    }
                                    style={{
                                      fontSize: '20px',
                                      display: 'flex',
                                      marginTop: '-10px',
                                      color: 'red',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    X
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ))}
                        {(!formVal.socials || formVal?.socials?.length < 5) && (
                          <Button
                            onClick={addSocial}
                            style={{
                              marginTop: '10px',
                              backgroundColor: '#008000',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            Thêm social
                          </Button>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <Label className='mb-1' for='description'>
                        Description
                      </Label>
                      <FormGroup>
                        <Input
                          name='description'
                          value={formVal?.description}
                          placeholder='Description'
                          type='textarea'
                          rows='5'
                          onChange={onInputChange}
                        />
                      </FormGroup>
                    </Col>
                    {user && authUser?.role?.name === 'Admin' && (
                      <Col lg={6}>
                        <FormGroup>
                          <Label className='mb-1' for='role'>
                            Trạng thái kích hoạt
                          </Label>
                          <Select
                            size='large'
                            name='status'
                            id='status'
                            value={formVal?.status}
                            defaultValue={1}
                            placeholder='Trạng thái'
                            onChange={onStatusChange}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Option value={1}>Kích hoạt tài khoản</Option>
                            <Option value={0}>Khóa tài khoản</Option>
                          </Select>
                        </FormGroup>
                      </Col>
                    )}
                    <Col lg={12}>
                      <FormGroup>
                        <Label className='mb-1' for='avatar'>
                          Hình ảnh
                        </Label>
                        {(!formVal.avatar || formVal.avatar === '') && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <label
                              className='custom-file-upload'
                              style={{
                                height: '100%',
                              }}
                            >
                              <Input
                                id='avatar'
                                name='avatar'
                                placeholder='Avatar'
                                accept='image/*'
                                type='file'
                                defaultValue={formVal.avatar || ''}
                                onChange={onInputChange}
                              />
                              Thêm hình ảnh
                            </label>
                          </div>
                        )}

                        <Input
                          id='avatar-change'
                          name='avatar'
                          placeholder='Avatar'
                          accept='image/*'
                          type='file'
                          defaultValue={formVal.avatar || ''}
                          onChange={onAvatarChange}
                          style={{ display: 'none' }}
                        />
                        {formVal.avatar && formVal.avatar !== '' && (
                          <Col
                            lg={3}
                            className='position-relative box-edit-image'
                          >
                            <img
                              src={formVal.avatar}
                              alt={formVal.avatar}
                              style={{
                                width: '100%',
                              }}
                            />
                            <button
                              onClick={openFilePicker}
                              className='button-edit-image'
                              type='button'
                            >
                              <MdOutlineEdit />
                            </button>
                          </Col>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className='text-center mt-4'>
                <Link to='/users'>
                  <Button outline size='large' className='mr-3'>
                    Quay lại
                  </Button>
                </Link>
                <Button size='large' onClick={() => addNewUser()}>
                  Lưu
                </Button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddUser;
