import React, { useCallback, useEffect, useState } from 'react';

//import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Col, Container, Input, InputGroup, Row, Button } from 'reactstrap';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai';
import {
  Badge,
  message,
  Space,
  Table,
  Modal,
  Select,
  Popconfirm,
  Pagination,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  addUser,
  deleteUser,
  getAllUsers,
  searchUser,
} from '../../helpers/helper';
const { Option } = Select;
const { Column } = Table;

const success = () => {
  message.success('Thành công');
};

const error = () => {
  message.error('Có lỗi xảy ra. Vui lòng thử lại!');
};
const UsersManagement = () => {
  document.title = 'Users Management';
  const [isModalAddUserVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const confirm = user => {
    if (user.id) {
      deleteUser(user.id)
        .then(res => {
          getUsers();
          success();
        })
        .catch(er => {
          error();
        });
    }
  };

  const cancel = e => {
    console.log(e);
  };

  const onSearchUser = async () => {
    searchUser(searchInput).then(data => setUsers(data));
  };

  useEffect(() => {
    getUsers();
  }, [pageIndex, pageSize]);

  const getUsers = () => {
    getAllUsers().then(res => {
      let userList;
      if (authUser?.role.name === 'Admin') {
        userList = res;
      } else {
        userList = res.filter(user => {
          return user.id === authUser?.id;
        });
      }

      setUsers(userList);
    });
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Thành Viên' pageTitle='Quản lý thành viên' />
          <Row className='mb-3'>
            {authUser?.role.name === 'Admin' && (
              <Col lg='5'>
                <div>
                  <InputGroup>
                    <Input
                      value={searchInput}
                      onChange={e => setSearchInput(e.target.value)}
                      placeholder='Tìm kiếm thành viên...'
                    />
                    <Button onClick={() => onSearchUser()}>
                      <i className='ri-search-line'></i>
                    </Button>
                  </InputGroup>
                </div>
              </Col>
            )}

            {authUser?.role.name === 'Admin' && (
              <Col lg='7'>
                <div className='text-right'>
                  <Link to='/user/add/new'>
                    <Button>Thêm mới</Button>
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={12}>
              <Table dataSource={users}>
                <Column
                  title='#'
                  key='id'
                  render={(val, rec, index) => {
                    return index + 1;
                  }}
                />
                <Column
                  title='Tên'
                  dataIndex='firstName'
                  key='firstName'
                  render={(value, record) => {
                    return <>{record?.firstName + ' ' + record?.lastName}</>;
                  }}
                />
                <Column
                  title='Tên đăng nhập'
                  dataIndex='username'
                  key='username'
                />
                <Column
                  title='Phân quyền'
                  dataIndex='role'
                  key='role'
                  render={value => {
                    return <>{value?.name}</>;
                  }}
                />
                <Column
                  title='Tình trạng'
                  dataIndex='status'
                  key='status'
                  render={value => {
                    return value ? (
                      <span>
                        <AiOutlineCheckSquare
                          color='green'
                          size='20'
                          style={{ marginRight: '10px' }}
                        />
                        Active
                      </span>
                    ) : (
                      <span>
                        <AiOutlineCloseSquare
                          color='red'
                          size='20'
                          style={{ marginRight: '10px' }}
                        />
                        Locked
                      </span>
                    );
                  }}
                />

                <Column
                  title='Hoạt động'
                  key='action'
                  render={(val, record) => {
                    return (
                      <>
                        {' '}
                        <Space size='middle'>
                          {(authUser?.role?.name === 'Admin' ||
                            val?.id === authUser?.id) && (
                            <>
                              {/* <Link to={{ pathname: '/users/' + val.id }}>
                                <i className='ri-eye-line action-icon'></i>
                              </Link> */}

                              <Link to={{ pathname: '/user/add/' + val.id }}>
                                <i className='ri-pencil-line action-icon'></i>
                              </Link>
                            </>
                          )}
                          {authUser?.role?.name === 'Admin' && (
                            <Popconfirm
                              title='Are you sure to delete this user?'
                              onConfirm={() => confirm(val)}
                              onCancel={cancel}
                              okText='Yes'
                              cancelText='No'
                            >
                              <i className='ri-delete-bin-line action-icon'></i>
                            </Popconfirm>
                          )}
                        </Space>
                      </>
                    );
                  }}
                />
              </Table>
              {/* <Pagination
                style={{ float: "right", marginTop: "10px" }}
                total={total}
                showTotal={(total) => `Total ${total} items`}
                showSizeChanger
                defaultPageSize={pageSize}
                current={pageIndex}
                onChange={(page, pageSize) => {
                  setPageIndex(page);
                  setPageSize(pageSize);
                }}
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersManagement;
